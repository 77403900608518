export default function (
  url = '',
  { method = 'GET', headers, params, parse = 'json', onUploadProgress } = {}
) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url, true)
    xhr.responseType = parse
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response)
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        })
      }
    }
    xhr.onerror = () =>
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      })

    if (typeof onUploadProgress === 'function') {
      xhr.upload.onprogress = onUploadProgress
    }

    xhr.upload.onerror = () =>
      reject({
        status: xhr.status,
        statusText: xhr.statusText,
      })

    if (headers) {
      Object.keys(headers).forEach(key => xhr.setRequestHeader(key, headers[key]))
    }

    if (params && typeof params === 'object') {
      params = Object.keys(params)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&')
    }
    xhr.send(params)
  })
}
